import React from "react";
import "./rarity.css";
import { motion } from "framer-motion";
import { fadeInFromLeft } from "../../../utils/Animation/FadeFromLeftAnimation";

const Rarity = () => (
  <div className="helixa__rarity section__padding" id="home">
    <div className="helixa__rarity-content">
      <motion.h1 {...fadeInFromLeft(0.2)} className="header__text">
        Card Rarity
      </motion.h1>
      <motion.p {...fadeInFromLeft(0.4)}>
        Within the game, only three rarities are available for play: Common,
        Uncommon, and Rare. The remaining rarities are purely cosmetic, designed
        to enhance the gaming experience without influencing gameplay.
      </motion.p>
      <motion.p {...fadeInFromLeft(0.6)}>
        These cosmetic rarities come with attractive bonuses like unique
        animations, bonus rewards, or even the ability to transform the
        appearance of a Legend completely.
      </motion.p>
      <motion.p {...fadeInFromLeft(0.8)}>
        Purchasing a booster pack adheres to a fixed distribution pattern: 7
        Commons, 4 Uncommons, 1 Rare, and 1 Special.
      </motion.p>
      <motion.p {...fadeInFromLeft(1)}>
        Helixa provides a diverse range of rarity tiers for our cards and items.
        Refer to the table below to understand the likelihood of acquiring each
        type of rarity.
      </motion.p>
      <div className="helixa__rarity-table">
        <table>
          <thead>
            <tr>
              <th>Rarity Type</th>
              <th>Probability</th>
              <th>Crafting</th>
              <th>Staking</th>
              <th>Reward Multiplier</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Common</td>
              <td>58.33%</td>
              <td>No</td>
              <td>No</td>
              <td>None</td>
            </tr>
            <tr>
              <td>Uncommon</td>
              <td>25.00%</td>
              <td>No</td>
              <td>No</td>
              <td>None</td>
            </tr>
            <tr>
              <td>Rare</td>
              <td>8.33%</td>
              <td>No</td>
              <td>No</td>
              <td>None</td>
            </tr>
            <tr>
              <td>Special Common</td>
              <td>4.17%</td>
              <td>No</td>
              <td>No</td>
              <td>None</td>
            </tr>
            <tr>
              <td>Special Uncommon</td>
              <td>3.17%</td>
              <td>No</td>
              <td>No</td>
              <td>None</td>
            </tr>
            <tr>
              <td>Bronze Rare</td>
              <td>0.65%</td>
              <td>Yes</td>
              <td>No</td>
              <td>1.5x</td>
            </tr>
            <tr>
              <td>Silver Rare</td>
              <td>0.20%</td>
              <td>Yes</td>
              <td>No</td>
              <td>2x</td>
            </tr>
            <tr>
              <td>Gold Rare</td>
              <td>0.10%</td>
              <td>Yes</td>
              <td>No</td>
              <td>3x</td>
            </tr>
            <tr>
              <td>Diamond Rare</td>
              <td>0.038%</td>
              <td>Yes</td>
              <td>No</td>
              <td>5x</td>
            </tr>
            <tr>
              <td>Etherial Rare</td>
              <td>0.010%</td>
              <td>No</td>
              <td>Yes</td>
              <td>20x</td>
            </tr>
            <tr>
              <td>Cosmic Rare</td>
              <td>0.002%</td>
              <td>No</td>
              <td>Yes</td>
              <td>50x</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default Rarity;
