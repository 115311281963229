import React from "react";
import "./feature.css";
import { motion } from "framer-motion";
import { fadeInFromLeft } from "../../utils/Animation/FadeFromLeftAnimation";

const Feature = ({ title, text, index }) => (
  <motion.div
    {...fadeInFromLeft(index * 0.3 + 0.1)}
    className="helixa__features-container__feature"
  >
    <div className="helixa__features-container__feature-title">
      <div />
      <h1>{title}</h1>
    </div>
    <div className="helixa__features-container_feature-text">
      <p>{text}</p>
    </div>
  </motion.div>
);

export default Feature;
