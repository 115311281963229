import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./index.css";

import { initializeApp } from "firebase/app";
import { BrowserRouter } from "react-router-dom";

const firebaseConfig = {
  apiKey: "AIzaSyA3-eRttVrDBnnbY6OOzuAGSVsun0NFTxs",
  authDomain: "helixa-users-prod.firebaseapp.com",
  projectId: "helixa-users-prod",
  storageBucket: "helixa-users-prod.appspot.com",
  messagingSenderId: "580851152410",
  appId: "1:580851152410:web:70326ed3e82b0627cb03a3",
  measurementId: "G-YEKF51RW0R",
};

initializeApp(firebaseConfig);

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
