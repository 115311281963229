import { motion } from "framer-motion";
import "./lore.css";
import { fadeInFromLeft } from "../../../utils/Animation/FadeFromLeftAnimation";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, FreeMode, Pagination } from "swiper/modules";
const Lore = () => {
  return (
    <>
      <div className="lore_bg">
        <div className="vignette"></div>
        <div className="content">
          <motion.div {...fadeInFromLeft(0.2)}>
            <h1 className="header__Text lore_h1">A Dying Emperor</h1>
          </motion.div>
          <div>
            <motion.p {...fadeInFromLeft(0.4)} className="lore_p">
              In the Age of Gates, the Benemor Dominion has blossomed into a
              realm of gold and power, extending its reach beyond the boundaries
              of known worlds. Its sudden grasp of arcane warfare has given
              wings to its swift conquest, with resistance fading into
              insignificance, as it stands now. Emperor Benim Cad, in the
              twilight of his life, gazes at the shadow of his own mortality.
              The seers of his court, on a world newly found, have stumbled upon
              a race old and secluded, the Hasilde they name themselves.
            </motion.p>
            <br />
            <motion.p {...fadeInFromLeft(0.6)} className="lore_p">
              In pursuit of their eternal youth, many a squadron of the elite
              Hara Gavea have been dispatched to delve into the secret heart of
              the Hasilde's world. But, unknowingly, they have set the Dominion,
              and indeed, the whole cosmos, on a course that shall reshape
              destiny for all time.
            </motion.p>
          </div>
        </div>
      </div>
      <div className="blog-section">
        <h2 className="blog-title">Discover More</h2>
        {/* <div className="blog-list">
          
        
         
         
        </div> */}
        <div className="slider">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={50}
            loop={true}
            autoplay={true}
            speed={1100}
            freeMode={true}
            modules={[Autoplay, FreeMode]}
            className="mySwiper"
          >
            <SwiperSlide>
              <motion.div {...fadeInFromLeft(0.8)} className="blog-preview">
                <a
                  href="https://mirror.xyz/0x1004257E42EC17560b186B9A157CAaa9000aABde/okWjdVQaeLI9aAcFR9he0X76AcgR7FOr9UPZpgnGWcQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://images.mirror-media.xyz/publication-images/c3RFHzWmnVNfF3AQm_RYC.png"
                    alt="Preview for The Woodpecker"
                  />
                  <p>The Woodpecker</p>
                </a>
              </motion.div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <motion.div {...fadeInFromLeft(1)} className="blog-preview">
                <a
                  href="https://mirror.xyz/0x1004257E42EC17560b186B9A157CAaa9000aABde/HrlcPcayuSawjbGsHmbR79KkaW-iF5TevMCdxy8WRYQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://images.mirror-media.xyz/publication-images/fidqqI0ZT_7D8DYYOLEmR.png"
                    alt="Preview for Lilus Guardian"
                  />
                  <p>Lilus Guardian</p>
                </a>
              </motion.div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <motion.div {...fadeInFromLeft(1.2)} className="blog-preview">
                <a
                  href="https://mirror.xyz/0x1004257E42EC17560b186B9A157CAaa9000aABde/Fd5bOziT-_r4rCuQPM_RcxzpEn6fRaOjl7IEqa8KtXM"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://images.mirror-media.xyz/publication-images/dbA8pEV7jvGlh8cLr7Ocu.png"
                    alt="Preview for Husk Blade"
                  />
                  <p>Husk Blade</p>
                </a>
              </motion.div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="blog-preview">
                <a
                  href="https://mirror.xyz/0x1004257E42EC17560b186B9A157CAaa9000aABde/Q79QyPkWrXSPQQ_NJFq6mUrdhUDq0wyJvhUwTjwhbk0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://images.mirror-media.xyz/publication-images/XEVp2sNzsIfj6DR4ebu0G.png"
                    alt="Preview for The Sheathers"
                  />
                  <p>The Sheathers</p>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="blog-preview">
                <a
                  href="https://mirror.xyz/0x1004257E42EC17560b186B9A157CAaa9000aABde/jSxtIML2JxwlsNC1mUsTcUNwro2BELcF_D82tgpnaPY"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://images.mirror-media.xyz/publication-images/XVjyIV4-2yW-I-ESDEk8m.jpeg"
                    alt="Preview for Ealdor Breath"
                  />
                  <p>Ealdor Breath</p>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="blog-preview">
                <a
                  href="https://mirror.xyz/0x1004257E42EC17560b186B9A157CAaa9000aABde/wa9tnmI8bCsTQakuukFqgUA8RUjnrzHTTq3yTVNOaMw"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://images.mirror-media.xyz/publication-images/cPSpp-MzHEq52L_-uAjyf.png"
                    alt="Preview for The Tahl: Mythos"
                  />
                  <p>The Tahl: Mythos</p>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="blog-preview">
                <a
                  href="https://mirror.xyz/0x1004257E42EC17560b186B9A157CAaa9000aABde/E9lCxDJq3f4NqvhJAd9K-Qcu4XysWzSaBg9Czxfryn8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://images.mirror-media.xyz/publication-images/uuccDFzcjhDK9LJ69sxXL.png"
                    alt="Preview for The Tahl'Askorah"
                  />
                  <p>The Tahl: Tahl'Askorah</p>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="blog-preview">
                <a
                  href="https://mirror.xyz/0x1004257E42EC17560b186B9A157CAaa9000aABde/yQkNxLh6bXozS0nwKcmhm7gpDYIrkHiHdgaxoCrNbOE"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://images.mirror-media.xyz/publication-images/C81Q1mAgwPcOhNyNoPfaL.png"
                    alt="Preview for The Tahl'Azuh"
                  />
                  <p>The Tahl'Azuh</p>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="blog-preview">
                <a
                  href="https://mirror.xyz/0x1004257E42EC17560b186B9A157CAaa9000aABde/bKiHX3Y0omR-QE8sZv3xvE6OW2D-GqVmBgdoFrsc0F4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://images.mirror-media.xyz/publication-images/iyVjGoUioEiHCPs0I3FWA.jpeg"
                    alt="Preview for The Tahl'Ajanar: Hadar and Hammers"
                  />
                  <p>The Tahl'Ajanar: Hadar and Hammers</p>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="blog-preview">
                <a
                  href="https://mirror.xyz/0x1004257E42EC17560b186B9A157CAaa9000aABde/zWNHZW4PDOD2JaoERJdeYu4tSImtpa1XSXRv-lJYQmQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://images.mirror-media.xyz/publication-images/30o-LNtDIyMiaU3Abe-BK.png"
                    alt="Preview for The Tahl'Ajanar: Workshops and Wealth"
                  />
                  <p>The Tahl'Ajanar: Workshops and Wealth</p>
                </a>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Lore;
