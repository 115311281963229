import React from "react";
import "./deckbuilding.css";
import { motion } from "framer-motion";
import { fadeInFromLeft } from "../../../utils/Animation/FadeFromLeftAnimation";

const DeckBuilding = () => (
  <div className="helixa__deckbuilding section__padding" id="deckbuilding">
    <div className="helixa__deckbuilding-content">
      <motion.h1 {...fadeInFromLeft(0.2)} className="header_text">
        Deckbuilding Rules
      </motion.h1>

      <motion.p {...fadeInFromLeft(0.4)}>
        Building your deck in Helixa involves strategic planning and
        understanding of the rules. These are the fundamental principles for
        deck building:
      </motion.p>
      {/* <div className="deck__main__card">
        <div className="deck__card">
          <strong>Main deck</strong>
          <p>
            Your main deck must contain a minimum of 30 cards consisting of
            Actions and Charms with a maximum of 3 copies per card.
          </p>
        </div>
        <div className="deck__card">
          <strong>Item deck</strong>
          <p>
            Your Item Deck is capped at 10 cards, featuring Items with up to
            three copies of each card.
          </p>
        </div>
        <div className="deck__card">
          <strong>Affinity</strong>
          <p>
            A deck is limited to the affinity of each party member. If you have
            2 Chaos Legends and 1 Radiant Legend, then you would be restricted
            to Chaos and Radiant cards in your deck. Legends will only gain
            might when using generic cards or cards which they have affinity
            for. No duplicate Legends in decks.
          </p>
        </div>
      </div> */}
      <ul>
        <motion.li {...fadeInFromLeft(0.6)}>
          <strong>Main deck:</strong> Your main deck must contain a minimum of
          30 cards consisting of Actions and Charms with a maximum of 3 copies
          per card.
        </motion.li>

        <motion.li {...fadeInFromLeft(0.8)}>
          <strong>Item deck:</strong> Your Item Deck is capped at 10 cards,
          featuring Items with up to three copies of each card.
        </motion.li>

        <motion.li {...fadeInFromLeft(1)}>
          <strong>Affinity:</strong> A deck is limited to the affinity of each
          party member. If you have 2 Chaos Legends and 1 Radiant Legend, then
          you would be restricted to Chaos and Radiant cards in your deck.
          Legends will only gain might when using generic cards or cards which
          they have affinity for. No duplicate Legends in decks.
        </motion.li>
      </ul>
    </div>
  </div>
);

export default DeckBuilding;
