import { motion } from "framer-motion";
import Logo from "../../../assets/logo.png";
import video from "../../../assets/video.mp4";

import "./Hero.css";
const hero = () => {
  return (
    <div className="helixa__hero">
      <video autoPlay={true} loop muted>
        <source src={video} />
      </video>
      <div className="helixa__hero__content">
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          src={Logo}
          alt=""
        />
      </div>
    </div>
  );
};

export default hero;
