import About from "./About/About";
import Collectibility from "./Collectibility/Collectibility";
import Demo from "./Demo/Demo";
import Faq from "./Faq/Faq";
import Hero from "./Hero/Hero";
import Players from "./players/Players";
const index = () => {
  return (
    <>
      <Hero />
      <About />
      <Players />
      <Collectibility />
      <Demo />
      <Faq />
    </>
  );
};

export default index;
