import HeaderAndCardSection from "../../../components/headerAndCardSection/headerAndCardSection";
import DeckBuilding from "../deckbuilding/deckbuilding";
import Authority from "../authority/authority";
import CardTypes from "../cardtypes/cardtypes";
import strategy from "../../../assets/knightcavalry1.png"; // TODO Remove at end
import knight1 from "../../../assets/knightcavalry1.png";
import knight2 from "../../../assets/knightcavalry2.png";
import knight3 from "../../../assets/knightcavalry3.png";
import potion1 from "../../../assets/potion27.png";
import potion2 from "../../../assets/potion22.png";
import potion3 from "../../../assets/potion35.png";
import "./gameplay.css";

const turns = [
  {
    imgSrc: knight1,
    title: "Action Phase",
    text: "The Action Phase begins with the player holding the Authority, during which each player draws two cards from their Main Deck and one card from their Item Deck. Players have the freedom to use an unlimited number of Action, Charm, and Item cards from their hand. Combat arrows, signifying attack directions, are generated at the start of the Action Phase.",
  },
  {
    imgSrc: knight2,
    title: "Combat Phase",
    text: "Legends execute attacks on the unit targeted by their combat arrow. Certain cards have the ability to influence the direction of combat arrows. The damage inflicted by Legends during an attack is evaluated against the opponent's health and armour. The speed of the Legends also plays a crucial role, with faster Legends attacking first.",
  },
  {
    imgSrc: knight3,
    title: "End Phase",
    text: "Upon the conclusion of a turn all temporary effects are nullified. Legends will recuperate health and mana depending on their current stats. Additionally, Legends undergo a level up process, enhancing their current stats for the next turn.",
  },
];

const authority = [
  {
    imgSrc: strategy,
    title: "Shared Turns",
    text: "In Helixa a turn is allocated to both players allowing both players to perform actions.",
  },
  {
    imgSrc: strategy,
    title: "Passing Authority",
    text: "The Authority, the ability to initiate actions, dynamically shifts between players throughout the course of a turn. The last player to make an action will pass the Authority to the other player.",
  },
  {
    imgSrc: strategy,
    title: "Starting the Game",
    text: "At the start of the game, a simple coin flip decides who holds the Authority.",
  },
  {
    imgSrc: strategy,
    title: "Progressing the Game",
    text: "Players continually transition the Authority back and forth until a mutual agreement is reached to advance to the combat phase, or when there are no feasible actions left to perform. It's important to note that Authority persists across turns.",
  },
];

const resources = [
  {
    imgSrc: potion1,
    title: "Health",
    text: "All Legends have Health. Their maximum health and regeneration is determined by their Strength stat. If your Legend loses all their Health, they die. If all your Legends die, you lose the game. Legends lose health by being hit in combat or taking damage from card effects.",
  },
  {
    imgSrc: potion3,
    title: "Mana",
    text: "All Legends have Mana. Mana is a resource used to activate Actions and Charm cards. A Legend's maximum Mana and Mana Regeneration is governed by their Intelligence stat.",
  },
  {
    imgSrc: potion2,
    title: "Might",
    text: "Might is a resource which accumulates the more actions a Legend takes through cards and combat. Might is used to equip items. Unlike Mana and Health, Might cannot be interacted with by the opponent and is solely affected by the active player’s actions.",
  },
];

const cardtypes = [
  {
    imgSrc: strategy,
    title: "Actions",
    text: "Can only be used once to apply an effect.",
  },
  {
    imgSrc: strategy,
    title: "Charms",
    text: "Once played will permanently remain on the field and apply an effect.",
  },
  {
    imgSrc: strategy,
    title: "Items",
    text: "Equipped to Legends to give stat bonuses and abilities.",
  },
  {
    imgSrc: strategy,
    title: "Legends",
    text: "The cards that make up your party. All other cards are used through Legends. When all party members die, that player loses the game. Every Legend card is defined by their stats and unique ability. Each legend has their strengths and weaknesses.",
  },
];

const GamePlay = () => {
  return (
    <>
      <div class="content">
        <HeaderAndCardSection
          header="Turn Structure"
          paragraph="Upon the conclusion of a turn all temporary effects are nullified."
          cards={turns}
        />
        <Authority />
        <HeaderAndCardSection
          header="Resources"
          paragraph="Upon the conclusion of a turn all temporary effects are nullified."
          cards={resources}
        />
        <CardTypes />
        <DeckBuilding />
      </div>
    </>
  );
};

export default GamePlay;
