import React from "react";
import "./card.css";
import { motion } from "framer-motion";
import { fadeInFromLeft } from "../../utils/Animation/FadeFromLeftAnimation";

const Card = ({ title, text, imgSrc, delay }) => (
  <motion.div
    {...fadeInFromLeft(delay)}
    className="helixa__card-container__card"
  >
    <div className="helixa__card-container__card-image">
      <img src={imgSrc} alt={title} />
    </div>
    <div className="helixa__card-container__card-title">
      <h1>{title}</h1>
    </div>

    <div className="helixa__card-container_card-text">
      <p>{text}</p>
    </div>
  </motion.div>
);

export default Card;
