import React from "react";
import "./headerAndCardSection.css";
import Card from "../Card/Card";
import { motion } from "framer-motion";
import { fadeInFromLeft } from "../../utils/Animation/FadeFromLeftAnimation";

const HeaderAndCardSection = ({ header = "", cards }) => {
  return (
    <div className="helixa__turns section__margin" id="whelixa">
      {header && (
        <motion.div {...fadeInFromLeft(0.3)} className="helixa__turns-heading">
          <h1 className="header__text">{header}</h1>
        </motion.div>
      )}
      <div className="helixa__turns-feature">
        {cards.map((card, index) => (
          <Card
            key={index}
            imgSrc={card.imgSrc}
            title={card.title}
            text={card.text}
          />
        ))}
      </div>
    </div>
  );
};

export default HeaderAndCardSection;
