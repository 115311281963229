import { useState } from "react";
import "./Faq.css";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { motion } from "framer-motion";
import { IoMdClose } from "react-icons/io";
const Faq = () => {
  return (
    <div className="helixa__faq">
      <motion.h2
        initial={{ y: 10, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 1, delay: 0.3 }}
        viewport={{ once: true }}
      >
        We’ve got the answers
      </motion.h2>
      <div className="helixa__faq__main">
        <FaqCard
          id={1}
          question="Will there be a single-player campaign?"
          description="Yes! A big focus for us is on the single-player content in Helixa. We want to make it super fun and immersive so we're putting a lot of emphasis on PVE."
        />
        <FaqCard
          id={2}
          question="Will Helixa be multiplayer?"
          description="Yes. Our lead designer is a passionate MTG player, and he's making sure like-minded people, who love competing, will be forever designing new decks and strategies."
        />
        <FaqCard
          id={3}
          question="What game engine are you using?"
          description="We're building with Godot."
        />
        <FaqCard
          id={4}
          question="What platforms will the game be released on?"
          description="The game will be released on PC and mobile. We'll look into releasing it on console, later."
        />
        <FaqCard
          id={5}
          question="How much will the game cost?"
          description="Helixa will be free-to-play."
        />
        <FaqCard
          id={5}
          question="Will there be a Closed/Open Beta and how do I sign up?"
          description="Yes, we will have a closed and open beta. When we know the launch date of both we'll share information on how to get in. For now you can create an account with us and join the Discord to stay updated."
        />
      </div>
      <p>
        Still have more questions? Join our&nbsp;
        <a
          href="https://discord.gg/D5bNRQ9zhG"
          target="_blank"
          rel="noopener noreferrer"
          class="discord-link"
        >
          Discord
        </a>
        .
      </p>
    </div>
  );
};
const FaqCard = (props) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 1, delay: 0.1 * props.id + 0.2 }}
      viewport={{ once: true }}
      className={`faq__card ${expanded ? "faq-card-bg" : ""}`}
    >
      <div className="faq__card__top" onClick={toggleExpand}>
        <h3>{props.question}</h3>
        <span className={`faq__icon ${expanded ? "faq__icon__active" : ""}`}>
          {expanded ? <IoMdClose /> : <AiOutlinePlus />}
        </span>
      </div>
      {expanded && <p>{props.description}</p>}
    </motion.div>
  );
};
export default Faq;
