import React from "react";
import helixaLogo from "../../../assets/logo.png";
import fuelLogo from "../../../assets/fuellogo.png";
import "./footer.css";

const Footer = () => (
  <div className="helixa__footer section__padding">
    <div className="helixa__footer-partners">
      <h4>Powered By</h4>
      <a href="https://www.fuel.network/" target="_blank" rel="noreferrer">
        <img src={fuelLogo} alt="fuel_logo" className="partner-logo" />
      </a>
    </div>

    <div className="helixa__footer-links">
      <div className="helixa__footer-links_logo">
        <img src={helixaLogo} alt="helixa_logo" />
      </div>
      <div className="helixa__footer-links_div">
        <h4>Socials</h4>
        <a
          href="https://twitter.com/HelixaTCG"
          target="_blank"
          rel="noreferrer noopener"
        >
          Twitter
        </a>
        <a
          href="https://www.facebook.com/HelixaTCG/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Facebook
        </a>
        <a
          href="https://www.instagram.com/HelixaTCG/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Instagram
        </a>
        <a
          href="https://mirror.xyz/0x1004257E42EC17560b186B9A157CAaa9000aABde"
          target="_blank"
          rel="noreferrer noopener"
        >
          Mirror
        </a>
      </div>
      <div className="helixa__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions</p>
        <p>Privacy Policy</p>
      </div>
      <div className="helixa__footer-links_div">
        <h4>Get in touch</h4>
        <a
          href="https://discord.gg/D5bNRQ9zhG"
          target="_blank"
          rel="noreferrer noopener"
        >
          Discord
        </a>
        <a href="mailto:info@helixatcg.com">info@helixatcg.com</a>
      </div>
    </div>

    <div className="helixa__footer-copyright">
      <p>@2024 Emberlight Entertainment LVC. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
