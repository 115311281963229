import { motion } from "framer-motion";
import "./About.css";

const About = () => {
  return (
    <>
      <div className="helixa__about__top"></div>
      <motion.h2
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.4 }}
        viewport={{ once: true }}
        className="helixa__about__heading"
      >
        The sins of the founders were met with final judgement. <br /> The flux
        of energies must remain dormant forever.
        <span></span>
        Heed their warning, or share their just fate.
      </motion.h2>
      <h4 className="helixa__about__small">
        Seer of Helixa,
        <br />
        The Great Divination of the Alkonost.
      </h4>
      <div className="line"></div>
    </>
  );
};

export default About;
