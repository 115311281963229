import { motion } from "framer-motion";
import "./Collectibility.css";
import { IoFingerPrintOutline } from "react-icons/io5";
import { AiOutlineCodeSandbox } from "react-icons/ai";
const Collectibility = () => {
  return (
    <>
      <div className="helixa__collectibility">
        <motion.h2
          initial={{ y: 10, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 0.4 }}
          viewport={{ once: true }}
        >
          Let's talk about collectibility...
        </motion.h2>
        <div className="collectibily__card">
          <CollectibilityCard
            delay={0.5}
            icon={<AiOutlineCodeSandbox />}
            title="Card Availability"
            description="Each set will be time-locked in the game store. After the period is up, the set can no longer be acquired. Players may purchase as much product as they want during this period."
          />
          <CollectibilityCard
            delay={0.7}
            icon={<Icon />}
            title="Reprints"
            description="The cards are game pieces first and foremost. All cards can be reprinted in any new set up until Ethereal Rarity. Cosmic Rare cards will stay exclusive to the first print run of a card."
          />
          <CollectibilityCard
            delay={0.9}
            title="Account Locked assets"
            description="Players may choose to account lock specific assets, subsequently taking them off chain. All assets are account locked by default."
            icon={<IoFingerPrintOutline />}
          />
        </div>
      </div>

      <div className="line"></div>
    </>
  );
};
const CollectibilityCard = (props) => {
  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 1, delay: props.delay }}
      viewport={{ once: true }}
      className="helixa__collectibilty__card"
    >
      <div className="collectibilty__card__img">{props.icon}</div>
      <h3>{props.title}</h3>
      <p>{props.description} </p>
    </motion.div>
  );
};
export default Collectibility;

const Icon = (props) => (
  <svg
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id="svg1176952156_1791"
    {...props}
  >
    <circle
      cx={50}
      cy={50}
      r={49}
      fillOpacity={0.89}
      stroke="url(#svg1176952156_1791_paint0_linear_666_3293)"
      strokeWidth={0}
    />
    <g clipPath="url(#svg1176952156_1791_clip0_666_3293)">
      <path
        d="M74.5005 57.0005C74.4978 61.3615 72.867 65.5645 69.9274 68.7859C66.9877 72.0073 62.9512 74.0151 58.6086 74.4158C54.266 74.8166 49.9302 73.5815 46.4507 70.9524C42.9712 68.3234 40.5987 64.4899 39.798 60.203C42.5911 60.7225 45.4687 60.5542 48.1822 59.7125C50.8957 58.8709 53.3635 57.3813 55.3724 55.3724C57.3813 53.3635 58.8709 50.8957 59.7125 48.1822C60.5542 45.4687 60.7225 42.5911 60.203 39.798C64.2194 40.5456 67.8476 42.6749 70.4589 45.8167C73.0702 48.9586 74.5 52.9151 74.5005 57.0005ZM57.0005 39.5005C58.0748 39.5015 59.1468 39.6011 60.203 39.798C59.6201 36.6642 58.1924 33.7491 56.0739 31.3674C53.9554 28.9857 51.2267 27.2278 48.1822 26.2835C45.1377 25.3393 41.8932 25.2445 38.7988 26.0093C35.7044 26.7742 32.8776 28.3697 30.6237 30.6237C28.3697 32.8776 26.7742 35.7044 26.0093 38.7988C25.2445 41.8932 25.3393 45.1377 26.2835 48.1822C27.2278 51.2267 28.9857 53.9554 31.3674 56.0739C33.7491 58.1924 36.6642 59.6201 39.798 60.203C39.3278 57.6771 39.4196 55.0787 40.0668 52.5922C40.714 50.1058 41.9007 47.7924 43.5427 45.8163C45.1848 43.8402 47.2418 42.2499 49.5676 41.1583C51.8935 40.0668 54.4312 39.5008 57.0005 39.5005Z"
        fill="#BDFF02"
      />
    </g>
    <defs>
      <linearGradient
        id="svg1176952156_1791_paint0_linear_666_3293"
        x1={2.95082}
        y1={4.59911e-7}
        x2={71.6624}
        y2={77.4311}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#292929" />
        <stop offset={1} stopColor="#292929" stopOpacity={0} />
      </linearGradient>
      <clipPath id="svg1176952156_1791_clip0_666_3293">
        <rect
          width={56}
          height={56}
          fill="white"
          transform="translate(22 22)"
        />
      </clipPath>
    </defs>
  </svg>
);
