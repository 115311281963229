import React, { useState, useEffect } from "react";
import {
  getAuth,
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import { Footer, Header, Helixa, Home } from "./pages/home";
import Home2 from "./pages/home2/index";
import { GamePlay } from "./pages/gameplay";
import { Rarity, CardMint } from "./pages/collector";
import { Account } from "./pages/account";
import { Navbar } from "./components";
import { Lore } from "./pages/lore";
import { Login, Register } from "./components";
import ScrollTop from "./utils/ScrollTop";

const App = () => {
  const [form, setForm] = useState("login");
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    console.log("Setting persistence..."); // Debug log

    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        console.log(
          "Persistence set. Setting up auth state changed listener..."
        ); // Debug log

        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
          if (firebaseUser) {
            console.log("User is signed in:", firebaseUser); // Debug log

            try {
              const token = await firebaseUser.getIdToken();
              console.log("Firebase ID token:", token); // Token log
              setToken(token);
            } catch (error) {
              console.error("Error fetching the ID token:", error);
            }
          } else {
            console.log("User is signed out or not initialized."); // Debug log
            setUser(null);
          }
        });

        return () => {
          console.log("Unsubscribing auth listener..."); // Debug log
          unsubscribe();
        };
      })
      .catch((error) => {
        console.error("Error with persistence:", error);
      });
  }, []);
  const location = useLocation();
  console.log(location);
  return (
    <>
      <div className="App">
        <div className="navbar_bg">
          <Navbar user={user} />
        </div>

        <Routes>
          {/* <Route
            path="/"
            element={
              <>
                <Home />
                <Footer />
              </>
            }
          /> */}
          <Route
            path="/"
            element={
              <>
                <Home2 />
                <Footer />
              </>
            }
          />
          <Route
            path="/lore"
            element={
              <>
                <Lore />
                <Footer />
              </>
            }
          />
          <Route
            path="/gameplay"
            element={
              <>
                <GamePlay />
                <Footer />
              </>
            }
          />
          <Route
            path="/collector-info"
            element={
              <>
                <Rarity />
                <CardMint />
                <Footer />
              </>
            }
          />
          <Route
            path="/account"
            element={<Account user={user} setUser={setUser} token={token} />}
          />
          <Route
            path="/login"
            element={
              form === "login" ? (
                <Login
                  onFormSwitch={setForm}
                  setToken={setToken}
                  setUser={setUser}
                />
              ) : (
                <Register onFormSwitch={setForm} />
              )
            }
          />
        </Routes>
        <ScrollTop />
      </div>
    </>
  );
};

export default App;
