import React, { useState } from "react";
import "./login.css";
import { motion } from "framer-motion";
import { fadeInFromLeft } from "../../utils/Animation/FadeFromLeftAnimation";

const Register = ({ onFormSwitch }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // To display error messages
  const [loading, setLoading] = useState(false); // To show loading status

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear any existing errors
    setLoading(true); // Indicate loading status

    const user = {
      username,
      email,
      password,
    };

    const apiEndpoint = "https://api.helixatcg.com/helixa-user/register";

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      const data = await response.json();

      if (response.ok) {
        console.log(data.message);
        onFormSwitch("login");
      } else {
        setError(
          data.error ||
            "An error occurred during registration. Please try again."
        );
      }
    } catch (error) {
      setError("Network error. Please try again later.");
      console.error("There was an error with the registration:", error);
    } finally {
      setLoading(false); // Loading complete in either case
    }
  };

  return (
    <div className="auth-form-container">
      {error && <div className="register-error">{error}</div>}{" "}
      {/* Display error message */}
      <form className="register-form" onSubmit={handleSubmit}>
        <label htmlFor="username">Username</label>
        <motion.input
          {...fadeInFromLeft(0.2)}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          type="text"
          placeholder="Enter your username"
          id="username"
          name="username"
          disabled={loading}
          className={error ? "input-error" : ""}
        />
        <label htmlFor="email">Email</label>
        <motion.input
          {...fadeInFromLeft(0.4)}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="example@mail.com"
          id="email"
          name="email"
          disabled={loading}
          className={error ? "input-error" : ""}
        />
        <label htmlFor="password">Password</label>
        <motion.input
          {...fadeInFromLeft(0.6)}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          placeholder="Create a password"
          id="password"
          name="password"
          disabled={loading}
          className={error ? "input-error" : ""}
        />
        <motion.button
          {...fadeInFromLeft(0.8)}
          type="submit"
          disabled={loading}
          className="register-button"
        >
          {loading ? (
            <span className="loading-text">Registering...</span>
          ) : (
            "Register"
          )}
        </motion.button>
      </form>
      <motion.button
        {...fadeInFromLeft(1)}
        className="link-btn"
        onClick={() => onFormSwitch("login")}
        disabled={loading}
      >
        Already have an account?
        <span>Login here.</span>
      </motion.button>
    </div>
  );
};

export default Register;
