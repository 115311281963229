import React from "react";
import "./players.css";
import lorevideo from "../../../assets/player.mp4";
import engagingvideo from "../../../assets/engaging.mp4";
import tradevideo from "../../../assets/tradevideo.mp4";
import { motion } from "framer-motion";
import img1 from "../../../assets/img1.PNG";
import img2 from "../../../assets/img2.PNG";

const Players = () => {
  return (
    <>
      <div className="helixa__players">
        <motion.h2
          initial={{ y: 10, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 0.2 }}
          viewport={{ once: true }}
        >
          Built by players.
        </motion.h2>
        <div className="players__card">
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.4 }}
            viewport={{ once: true }}
            className="helixa__players__card"
          >
            <video autoPlay={true} loop muted>
              <source src={lorevideo} />
            </video>
            <h3>Rich Lore</h3>
            <p>
              Explore the expansive universe through the cards and single player
              campaign.{" "}
            </p>
          </motion.div>

          <motion.div
            initial={{ y: 10, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.4 }}
            viewport={{ once: true }}
            className="helixa__players__card"
          >
            <video autoPlay={true} loop muted>
              <source src={engagingvideo} />
            </video>
            <h3>Engaging Gameplay</h3>
            <p>
              Helixa's unique turn structure promotes a quick back-and-forth
              game while adding an additional layer of complexity.{" "}
            </p>
          </motion.div>

          <motion.div
            initial={{ y: 10, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.4 }}
            viewport={{ once: true }}
            className="helixa__players__card"
          >
            <video autoPlay={true} loop muted style={{ objectPosition: 'center' }}>
              <source src={tradevideo} />
            </video>
            <h3>Trading Cards</h3>
            <p>
              Back to what made this genre great. Helixa will have an open
              market where all players may freely trade their cards with each
              other.{" "}
            </p>
          </motion.div>
        </div>
        <div className="helixa__players__bottom">
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.8 }}
            viewport={{ once: true }}
            className="helixa__players__bottom__card"
          >
            <div className="bottom__card__left">
              <h3>Balanced Curve</h3>
              <p>
                Simple enough for new players to engage while maintaing a high
                skill ceiling for veterans.
              </p>
            </div>
            <div className="bottom__card__right">
              <img src={img1} alt="" />
            </div>
          </motion.div>
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.9 }}
            viewport={{ once: true }}
            className="helixa__players__bottom__card"
          >
            <div className="bottom__card__left">
              <h3>Clear Updates</h3>
              <p>
                There will be a clear update schedule regarding new releases and
                balance patches.
              </p>
            </div>
            <div className="bottom__card__right">
              <img src={img2} alt="" />
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

const PlayersCard = (props) => {
  return (
    <motion.div
      initial={{ y: 10 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 1, delay: props.delay }}
      viewport={{ once: true }}
      className="helixa__players__card"
    >
      <h3>{props.title}</h3>
      <p>{props.description} </p>
    </motion.div>
  );
};

export default Players;
