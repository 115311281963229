import React from "react";
import Feature from "../../../components/feature/Feature";
import "./cardtypes.css";
import image from "../../../assets/darkwanderer101.png";
import { motion } from "framer-motion";
import { fadeInFromLeft } from "../../../utils/Animation/FadeFromLeftAnimation";

const featuresData = [
  {
    title: "Actions",
    text: "Can only be used once to apply an effect.",
  },
  {
    title: "Charms",
    text: "Once played will permanently remain on the field and apply an effect.",
  },
  {
    title: "Items",
    text: "Equipped to Legends to give stat bonuses and abilities.",
  },
  {
    title: "Legends",
    text: "The cards that make up your party. All other cards are used through Legends. When all party members die, that player loses the game. Every Legend card is defined by their stats and unique ability. Each legend has their strengths and weaknesses.",
  },
];

const types = () => (
  <div className="helixa__types section__padding" id="features">
    <motion.div {...fadeInFromLeft(0.3)} className="helixa__types-heading">
      <h1 className="header__text">Card Types</h1>
    </motion.div>
    <div className="helixa__types-content">
      <motion.div
        initial={{ scale: 0.2 }}
        whileInView={{ scale: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
        className="helixa__types-image"
      >
        <img src={image} alt="Description of the card" />
      </motion.div>
      <motion.div {...fadeInFromLeft(0.5)} className="helixa__types-container">
        {featuresData.map((item, index) => (
          <Feature
            title={item.title}
            text={item.text}
            key={item.title + index}
            index={index}
          />
        ))}
      </motion.div>
    </div>
  </div>
);
export default types;
