import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './account.css';

const Account = (props) => {
  const { user, setUser, token } = props;
  const [error, setError] = useState('');

  useEffect(() => {
    if (token) {
      fetchUserData(token);
    } else {
      setError('No token available.');
    }
  }, [token]);

  const fetchUserData = async () => {
    try {
      const response = await fetch('https://api.helixatcg.com/helixa-user/profile', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.json)

      if (!response.ok) {
        throw new Error('Could not fetch user profile.');
      }

      const userData = await response.json();
      setUser(userData);
    } catch (err) {
      setError('Failed to fetch user data.');
      console.error('Error fetching user data:', err);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
    });

    return () => unsubscribe();
  }, []);

  const renderDownloadLink = () => {
    if (user && user.betaAccess) {
      return (
        <div className="download-link">
          <a href="">Download Demo</a>
        </div>
      );
    } else {
      return <p>Coming soon...</p>;
    }
  };

  return (
    <div className="account_bg">
      <div className="content">
        <h1 className="header__Text account_h1">Account Details</h1>
        {error && <div className="error-message">{error}</div>}
        {user ? (
          <>
            <div className="account-info">
              <p><strong>Username:</strong> {user.username || 'N/A'}</p>
              <p><strong>Email:</strong> {user.email || 'N/A'}</p>
              <p><strong>{renderDownloadLink()}</strong></p>
            </div>
          </>
        ) : (
          <p>No user data available.</p>
        )}
      </div>
    </div>
  );
};

export default Account;
