import React from "react";
import HeaderAndCardSection from "../../../components/headerAndCardSection/headerAndCardSection";
import "./cardmint.css";
import strategy from "../../../assets/soldier.png";
import tome from "../../../assets/tome6.png";
import tome2 from "../../../assets/tome1.png";
import mage from "../../../assets/mage1.png";

const cardmint = [
  {
    imgSrc: tome2,
    title: "Set Availability",
    text: "Each product from a specific set will be available in the shop for a period of 12 months. During this time, customers have the freedom to purchase any quantity of the product. Once this time frame has elapsed, the product is removed from the store. Subsequently, acquiring the product from that particular set is only possible through secondary market transactions.",
  },
  {
    imgSrc: tome,
    title: "Reprint Policy",
    text: "The Helixa team reserves the right to reprint any base card into new sets, obtainable up to the Etherial rarity. However, the Cosmic rarity of any card is exclusive to its first print run.",
  },
  {
    imgSrc: mage,
    title: "Account Locked Assets",
    text: "Users have the option to lock assets, which permanently assigns the card to their Helixa account and blocks trading. It retains all previous attributes and gains a 'burned' emblem.",
  },
];

const CardMint = () => {
  return <HeaderAndCardSection header="" paragraph="" cards={cardmint} />;
};

export default CardMint;
