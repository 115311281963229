import React from "react";
import "./header.css";
import helixaLogo from "../../../assets/logo.png";

const Header = () => (
  <div className="helixa__header section__padding" id="home">
    <div className=".helixa_header_logo-container">
      <div className="helixa__header_logo">
        <img src={helixaLogo} alt="helixa_logo" />
      </div>
    </div>
    <div className="helixa__header-content">
      <p>
        Helixa is an upcoming digital trading card game set in a dark-fantasy
        universe of epic proportions
      </p>
    </div>
  </div>
);

export default Header;
