import React, { useEffect, useRef, useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import logo from "../../assets/logo.png";
import { Link, NavLink } from "react-router-dom";
import "./navbar.css";
import useClickOutsideDetector from "../../Hooks/useClickOutsideDetector";

const Navbar = ({ user }) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);

  const handleDropdown = () => {
    setToggleDropdown(!toggleDropdown);
  };
  const getActiveStyle = ({ isActive }) => ({
    color: isActive ? "rgb(221, 255, 0)" : "",
  });
  const [isSticky, setIsSticky] = useState(false);

  // Track scrolling to toggle sticky navbar
  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const menuref = useRef();
  useClickOutsideDetector(menuref, () => {
    setToggleMenu(false);
  });

  return (
    <div className={`helixa__navbar ${isSticky ? "sticky" : ""}`}>
      <div className="helixa__navbar-links">
        <div className="helixa__navbar-links_logo">
          <Link to={"/"}>
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className="helixa__navbar-links_container">
          <p>
            <NavLink style={getActiveStyle} to="/">
              Home
            </NavLink>
          </p>
          <p>
            <NavLink style={getActiveStyle} to="/lore">
              Lore
            </NavLink>
          </p>
          <p>
            <NavLink style={getActiveStyle} to="/gameplay">
              Gameplay
            </NavLink>
          </p>
          <p>
            <NavLink style={getActiveStyle} to="/collector-info">
              Collector Info
            </NavLink>
          </p>
        </div>
      </div>

      <div className="helixa__navbar-login">
        {user ? (
          <p>
            <Link to="/account">Hi, {user.username}</Link>
          </p>
        ) : (
          <p>
            <Link to="/login" className="login">
              Log in
            </Link>
          </p>
        )}
      </div>

      <div className="helixa__navbar-menu" ref={menuref}>
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {/* {toggleMenu && ( */}
        <div
          className={`helixa__navbar-menu_container ${
            toggleMenu ? "slide-in" : "slide-out"
          }`}
        >
          {/* // <div className="helixa__navbar-menu_container scale-up-center"> */}
          <Link to={"/"} onClick={() => setToggleMenu(false)}>
            <img src={logo} alt="Logo" />
          </Link>
          <div className="helixa__navbar-menu_container-links">
            <p>
              <Link onClick={() => setToggleMenu(false)} to="/">
                Home
              </Link>
            </p>
            <p>
              <Link onClick={() => setToggleMenu(false)} to="/lore">
                Lore
              </Link>
            </p>
            <p>
              <Link onClick={() => setToggleMenu(false)} to="/gameplay">
                Gameplay
              </Link>
            </p>
            <p>
              <Link onClick={() => setToggleMenu(false)} to="/collector-info">
                Collector Info
              </Link>
            </p>
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default Navbar;
