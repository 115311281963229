import "./Demo.css";

import { motion } from "framer-motion";
const Demo = () => {
  return (
    <>
      <div className="helixa__demo">
        <div className="demo-left">
          <motion.h2
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.8 }}
            viewport={{ once: true }}
          >
            "Helixa captivates across genres, from its intuitive, deep strategy
            for action lovers to its rich, layered lore for role-players,
            standing out as a TCG that long-time fans can't wait to explore
            further."
          </motion.h2>
        </div>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          viewport={{ once: true }}
          className="demo-right"
        >
          <p>
            Helixa TCG Demo Day
            <br />
            @Luckshack, Cape Town
          </p>
        </motion.div>
      </div>
      <div className="line"></div>
    </>
  );
};

export default Demo;
