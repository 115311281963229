import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithCustomToken } from "firebase/auth"; // Import Firebase auth functions
import "./login.css";
import { motion } from "framer-motion";
import { fadeInFromLeft } from "../../utils/Animation/FadeFromLeftAnimation";

// Import the Fuel SDK (disable to test site in windows)
// import { Fuel } from '@fuel-wallet/sdk';

const Login = ({ onFormSwitch, setUser, setToken }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // const fuel = new Fuel();

  // Function to authenticate with Firebase using the custom token
  const handleFirebaseSignIn = async (customToken) => {
    const auth = getAuth();
    try {
      const userCredential = await signInWithCustomToken(auth, customToken);
      console.log("Firebase sign-in successful:", userCredential.user);
      setUser(userCredential.user); // Update user state with Firebase user
      setToken(await userCredential.user.getIdToken()); // Update token state with Firebase ID token
    } catch (error) {
      console.error(`Firebase sign-in error ${error.code}:`, error.message);
      setError("Failed to sign in with Firebase. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    const apiEndpoint = "https://api.helixatcg.com/helixa-user/login";

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Login Successful!", data);
        if (data.authToken) {
          // Use the authToken from your API to sign in with Firebase
          await handleFirebaseSignIn(data.authToken);
          navigate("/account");
        }
      } else {
        setError(
          data["login error"] || "Invalid credentials. Please try again."
        );
      }
    } catch (err) {
      setError("Network error. Please try again later.");
      console.error("There was an error logging in:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleFuelLogin = async () => {
    try {
      //don't worry about it
    } catch (err) {
      console.error("Error connecting to Fuel Wallet:", err);
    }
  };

  return (
    <div className="auth-form-container">
      {error && <div className="login-error">{error}</div>}
      <form className="login-form" onSubmit={handleSubmit}>
        <label htmlFor="email">Email</label>
        <motion.input
          {...fadeInFromLeft(0.2)}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          placeholder="email@example.com"
          id="email"
          name="email"
          disabled={loading}
          className={error ? "input-error" : ""}
        />
        <label htmlFor="password">Password</label>
        <motion.input
          value={password}
          {...fadeInFromLeft(0.4)}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          placeholder="********"
          id="password"
          name="password"
          disabled={loading}
          className={error ? "input-error" : ""}
        />
        <motion.button
          {...fadeInFromLeft(0.6)}
          type="submit"
          disabled={loading}
          className="login-button"
        >
          {loading ? "Logging in..." : "Log In"}
        </motion.button>
      </form>

      {/* <button onClick={handleFuelLogin} disabled={loading} className="fuel-login-button">
        Login with Fuel Wallet
      </button> */}

      <motion.button
        {...fadeInFromLeft(0.8)}
        className="link-btn"
        onClick={() => onFormSwitch("register")}
        disabled={loading}
      >
        Don't have an account?
        <span>Register here.</span>
      </motion.button>
    </div>
  );
};

export default Login;
