import React from "react";
import Feature from "../../../components/feature/Feature";
import "./authority.css";
import image from "../../../assets/general101.png";
import { motion } from "framer-motion";
import { fadeInFromLeft } from "../../../utils/Animation/FadeFromLeftAnimation";

const featuresData = [
  {
    title: "Shared Turns",
    text: "In Helixa a turn is allocated to both players allowing both players to perform actions.",
  },
  {
    title: "Passing Authority",
    text: "The Authority, the ability to initiate actions, dynamically shifts between players throughout the course of a turn. The last player to make an action will pass the Authority to the other player.",
  },
  {
    title: "Starting the Game",
    text: "At the start of the game, a simple coin flip decides who holds the Authority.",
  },
  {
    title: "Progressing the Game",
    text: "Players continually transition the Authority back and forth until a mutual agreement is reached to advance to the combat phase, or when there are no feasible actions left to perform. It's important to note that Authority persists across turns.",
  },
];

const Authority = () => (
  <div className="helixa__authority section__padding" id="features">
    <motion.div {...fadeInFromLeft(0.2)} className="helixa__authority-heading">
      <h1 className="header__text">Authority</h1>
    </motion.div>
    <div className="helixa__authority-content">
      <motion.div
        initial={{ scale: 0.2 }}
        whileInView={{ scale: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
        className="helixa__authority-imagee"
      >
        <img src={image} alt="Description " id="authority-image" />
      </motion.div>
      <motion.div
        {...fadeInFromLeft(0.6)}
        className="helixa__authority-container"
      >
        {featuresData.map((item, index) => (
          <Feature
            title={item.title}
            text={item.text}
            key={item.title + index}
          />
        ))}
      </motion.div>
    </div>
  </div>
);
export default Authority;
